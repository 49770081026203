import React from 'react';

const EmailLink = () => {
  return (
    <p className="text-light text-center email-link">
      <a href="mailto:tylerlovell@gmail.com" className="text-info">say hello</a>
    </p>
  );
};

export default EmailLink;
