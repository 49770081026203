import React from 'react';
import ThreeJSScene from './ThreeJSScene';
import '../index.css'; 
import '../App.css'; 

const BeatTraveller = () => {
  return (
<div className="App">
    <div>
    <center>
      <h1>Project Beat Traveller</h1>
      <p>
      Beat Traveller (working title) is a fast-paced musical dodgeball shooter in which players explore digital wastelands to find the components necessary to rebuild the Tower of Babel. Along the journey, players confront celestial beings and fend off the fragments of Metatron. To stay alive, they must skillfully manipulate time and rhythm while unraveling the mystery behind the tower's collapse.
      </p>
    
      <iframe width="560" height="315" src="https://www.youtube.com/embed/JoC7FuYx5rU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <div>
      <h1>Mechanics Walkthrough</h1>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/KFEmO2gsWYc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      </center>
      <ThreeJSScene />
    </div>
    </div>
  );
};

export default BeatTraveller;
