import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { DeviceOrientationControls } from 'three-stdlib/controls/DeviceOrientationControls';

const ThreeJSScene = () => {
  const containerRef = useRef();
  const curveSegments = 200;
  const radialSegments = 4;

  useEffect(() => {
    // Scene and Camera
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    scene.background = new THREE.Color(0x404040); // Set the background color to pure black

    // Device Orientation Controls
    let controls;
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      controls = new DeviceOrientationControls(camera);
      controls.connect();
      controls.update();
    }
    window.addEventListener('orientationchange', handleOrientationChange);

    // Renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    // Wave Model
    const torus = createWaveModel(scene, curveSegments, radialSegments);


    // Lighting
    createLighting(scene);

    // Particles
    const particlesMesh = createParticles(scene);

    // Camera Position
    camera.position.z = 20;

    // Mouse and Animation
    const mouse = new THREE.Vector2();
    window.addEventListener('mousemove', handleMouseMove(mouse, torus, updateWave, curveSegments, radialSegments));
    const speedArray = createParticlesSpeedArray(10000);
    animate(scene, camera, renderer, mouse, particlesMesh, torus, controls, isMobile, speedArray, updateWave, curveSegments, radialSegments);

    return () => {
      // Cleanup
      if (isMobile) {
        controls.disconnect();
      }
      renderer.domElement.remove();
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />
  );
};

// Helper functions for creating the scene

const createWaveModel = (scene, curveSegments, radialSegments) => {
  const radius = 100; // Increase radius for more immersive effect
  const tubeRadius = 0.2;
  const torusGeometry = new THREE.TorusGeometry(radius, tubeRadius, radialSegments, curveSegments);

  const torusMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
  const torus = new THREE.Mesh(torusGeometry, torusMaterial);
  scene.add(torus);

  return torus;
};



const createLighting = (scene) => {
  const pointLight = new THREE.PointLight(0xaaaaaa, 1, 1000);
  pointLight.position.set(50, 50, 50);
  scene.add(pointLight);
  scene.add(new THREE.AmbientLight(0x404040, 0.5));
};

const createParticles = (scene) => {
  const particlesGeometry = new THREE.BufferGeometry();
  const particlesCount = 10000;
  const posArray = new Float32Array(particlesCount * 3);
  const torusRadius = 100; // Make sure to use the same value as the torus radius

  for (let i = 0; i < particlesCount * 3; i += 3) {
    const theta = Math.random() * Math.PI * 2;
    const phi = Math.acos(2 * Math.random() - 1);
    const r = Math.random() * torusRadius;

    posArray[i] = r * Math.sin(phi) * Math.cos(theta);
    posArray[i + 1] = r * Math.sin(phi) * Math.sin(theta);
    posArray[i + 2] = r * Math.cos(phi);
  }

  particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
  const particleTexture = createRadialGradientTexture();
  const particlesMaterial = createParticlesMaterial(particleTexture);
  const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
  scene.add(particlesMesh);
  return particlesMesh;
};


  
  const createRadialGradientTexture = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 128;
  canvas.height = 128;
  
  const context = canvas.getContext('2d');
  const gradient = context.createRadialGradient(64, 64, 0, 64, 64, 64);
  gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
  gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.5)');
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
  
  context.fillStyle = gradient;
  context.fillRect(0, 0, 128, 128);
  
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  
  return texture;
  };
  
  const createParticlesMaterial = (particleTexture) => {
  const particlesMaterial = new THREE.PointsMaterial({
  size: 1.5,
  color: 0x111111,
  transparent: true,
  opacity: 0.5,
  map: particleTexture,
  depthTest: false,
  });
  
  particlesMaterial.onBeforeCompile = (shader) => {
  shader.fragmentShader = shader.fragmentShader.replace(
  'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
  [
  'float alpha = texture2D( map, gl_PointCoord ).a;',
  'gl_FragColor = vec4( outgoingLight, diffuseColor.a * alpha );',
  ].join('\n')
  );
  };
  
  return particlesMaterial;
  };
  
  const handleMouseMove = (mouse, torus, updateWave, curveSegments, radialSegments) => (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  
    // Update wave geometry based on mouse movement
    updateWave(torus, mouse.y * 0.1, 10, mouse.x * 5, curveSegments, radialSegments);
  };
  
  
  
  
  
  const handleOrientationChange = () => {
  window.location.reload();
  };
  
  const animate = (
    scene, camera, renderer, mouse, particlesMesh, torus, controls, isMobile, speedArray, updateWave, curveSegments, radialSegments
  ) => {
    const clock = new THREE.Clock();
  
    const render = () => {
      requestAnimationFrame(render);
      // Update wave geometry based on elapsed time
      const elapsedTime = clock.getElapsedTime();
      updateWave(torus, elapsedTime, 10, 5, curveSegments, radialSegments);
      if (isMobile) {
        controls.update();
      }
  
      updateParticles(particlesMesh, speedArray); // Update particles based on mouse position
  
      // Apply the risograph grain effect
      renderer.render(scene, camera);
      renderer.domElement.style.filter = `grayscale(100%) brightness(95%) contrast(110%)`;
  
      // Move the content slightly with mouse movement
      camera.position.x += ((mouse.x * 10) - camera.position.x) * 0.05;
      camera.position.y += ((-mouse.y * 10) - camera.position.y) * 0.05;
      camera.lookAt(scene.position);
    };
  
    render();
  };
  

const createParticlesSpeedArray = (particlesCount) => {
const speedArray = new Float32Array(particlesCount * 3);

for (let i = 0; i < particlesCount * 3; i++) {
  speedArray[i] = Math.random() * 0.0005 + 0.0001;
  }
  
  return speedArray;
  };
  
  const updateParticles = (particlesMesh, speedArray) => {
    const positions = particlesMesh.geometry.attributes.position.array;
    const elapsedTime = performance.now() * 0.001;
  
    for (let i = 0; i < positions.length; i += 3) {
      const offsetX = Math.sin(elapsedTime * speedArray[i]);
      const offsetY = Math.sin(elapsedTime * speedArray[i + 1]);
      const offsetZ = Math.cos(elapsedTime * speedArray[i + 2]);
  
      positions[i] += offsetX * 0.01;
      positions[i + 1] += offsetY * 0.01;
      positions[i + 2] += offsetZ * 0.01;
    }
  
    particlesMesh.geometry.attributes.position.needsUpdate = true;
  };
  
  
  const updateWave = (torus, time, amplitude, frequency, curveSegments, radialSegments) => {
  const geometry = torus.geometry;
  
  for (let j = 0; j <= radialSegments; j++) {
  for (let i = 0; i < curveSegments; i++) {
  const yOffset = Math.sin((i / curveSegments) * Math.PI * 2 * frequency + time) * amplitude;
  const vertexIndex = j * (curveSegments + 1) + i;
  geometry.attributes.position.array[vertexIndex * 3 + 1] = yOffset;
  }
  }
  
  geometry.attributes.position.needsUpdate = true;
  };
  
  export default ThreeJSScene;