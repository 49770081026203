import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThreeJSScene from './components/ThreeJSScene';
import StudioName from './components/StudioName';
import EmailLink from './components/EmailLink';
import BeatTraveller from './components/BeatTraveller';
import './index.css'; 
import './App.css'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/beat-traveller" element={<BeatTraveller />} />
        </Routes>
      </div>
    </Router>
  );
}

// Wrap your existing components in a new component for the homepage
function HomePage() {
  return (
    <>
      <ThreeJSScene />
      <StudioName />
      <EmailLink />
    </>
  );
}

export default App;
